.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it overlays above other content */
}

/* Overlay content container */
.overlay-content {
  text-align: center;
  color: white;
  background-color: rgba(0,0,0,0.9);
  border: 2px solid white;
  border-radius: 0.5em;
  width: 40%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Throbber (spinner) */
.spinner {
  width: 40px;
  height: 40px;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-top: 5px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px; /* Space between spinner and message */
  user-select: none;
}

/* Throbber animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Overlay message text styling */
.overlay-message {
  font-size: 18px;
  font-weight: bold;
  color:"#fff"
}




.button {
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}