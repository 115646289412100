
@keyframes slideInFromBottom {
    0% {
      transform: translateY(10%);
      opacity: .6;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

.tab-wrapper {
  position: sticky;
  z-index: 3;
    animation: .5s ease-out 0s 1 slideInFromBottom;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    --logo-green1: rgb(122, 178, 25);
    --logo-green2: rgb(74, 116, 40);
    --background: rgb(218, 218, 218);
    width: 100vw;
    background-color: rgba(255, 255, 255, 1);
    height: 100%;

}
.tab-wrapper.login {
  background-color: rgba(0, 0, 0, 0.001);
  box-shadow: none;
  width: 100vw;
  background-image: linear-gradient(rgb(122, 178, 25) 200px,rgb(210, 177, 10));
}

.header-image-wrapper {
 width: 100vw;

  max-height: 50vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  user-select: none;
  box-shadow: 0px 10px 20px black;

}
.header-image-wrapper.bottom {
  align-items: flex-end;
}

.header-image {
  width: 100%;
}



.tab-body-wrapper {
  width: 80%;
  padding-bottom: 10%;
}
.tab-title {
  color: var(--logo-green2);
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.tab-header {
  color: goldenrod;
  text-decoration: underline;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  

}
.tab-body {
  color: black;
  font-size: 1.4rem;
  text-align: left;
}

.tab-ul {
  color: black;

}
.tab-li {
  text-align: left;
  font-size: 1.4rem;

}





.portfolio-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.display-wrapper {
  display: flex;
  justify-content: center;
  width: 40%;
  height: 400px;
  position: relative;
  border: 1px solid black;
  box-shadow: 0 0 10px black;
  margin: 1%;
  transition: all .2s ease-in-out;
}
.display-wrapper:hover {
  scale: 1.1;
}
.display-wrapper:hover .image-display-description {
  background-image: linear-gradient(rgba(45, 45, 45, 0.1) 5%,rgba(0, 0, 0, 0.001) 60%, rgba(45, 44, 44, 0.1));
}



.image-display {
  width: 100%;
  height: 100%;
}
.image-description-wrapper {
  position: absolute;
}
.image-display-description {
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: white;
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgb(45, 45, 45) 5%,rgba(0, 0, 0, 0.001) 60%, rgb(45, 44, 44));
}
.image-display-description2 {
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: white;
  position: absolute;
  z-index: 2;
  bottom: 0;
}










.login-wrapper {
  padding: 5%;
  height: 60vh;
  width: 300px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--logo-green2);
  background-color: white;
  border: 2px solid rgb(40, 40, 40);
  border-radius: 0.3em;
  box-shadow: 0px 0px 20px black;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.login-form-container {
  width: 350px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.login-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #5a4bb7;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 15px;
}

.input-group select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #f9fbff;
  appearance: none; /* Removes default arrow */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="%23333333" d="M0 0l5 5 5-5z"/></svg>'); /* Custom arrow */
  background-repeat: no-repeat;
  background-position: right 10px center;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-group label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #333333;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #f5f9ff;
}

.sign-in-button {
  background-color: #5a4bb7;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.sign-in-button:hover {
  background-color: #48359f;
}

.signup-link {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #333333;
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.signup-link a {
  color: #5a4bb7;
  font-weight: bold;
  text-decoration: none;
}

.divider {
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
  color: #999999;
  position: relative;
}

.divider span {
  background-color: #ffffff;
  padding: 0 10px;
  position: relative;
  z-index: 1;
}

.divider:before {
  content: '';
  display: block;
  height: 1px;
  background-color: #e0e0e0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 0;
}

.google-signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #333333;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-signin-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.google-signin-button:hover {
  background-color: #f5f5f5;
}

.contentBox {
  display: flex;
  flex-direction: row;
  min-height: 4em;
  border-radius: 8;
  padding: 1em;
  width: 90%;
  background-color: #ededed;
}
.contentImageBox {

}
.contentImage {

}
.contentInBox {
  border: 2px solid red;
  display: flex;
  flex-direction: column;
}
.contentInBox .left {
  align-items: flex-start;
}
.contentInBox .right {
  align-items: flex-end;
}















@media screen and (max-width: 1180px) {
  .display-wrapper {
    width: 80%;
    height: 400px;
  }

}
@media screen and (max-width: 800px) {
  .display-wrapper {
    height: 180px;
  }
  .header-image-wrapper {
    max-height: 30vh;
  }
  .login-form-container {
    width: 250px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    user-select: none;
  }
}