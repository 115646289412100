h2 {
  pointer-events: none;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.App {
  --background: rgb(218, 218, 218);
  --background2: rgb(127, 30, 30);
  --logo-green1: rgb(122, 178, 25);
  --logo-green2: rgb(74, 116, 40);
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 150vh;
  
}

.App-header {
  background-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.05));
  min-width: 100vw;
  left: 0;
  position: -webkit-fixed;
  position: fixed;
  z-index: 100;
  top: 0;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  user-select: none;
  
}
#TabHome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transition: font-size 0.1s;
}
#TabHome:hover {
  cursor: pointer;
  font-weight: bold;
  font-size: x-large;
}                     
#TabHome:hover .logo-img {
  transform: scale(1.2);
}
#TabHome:hover #logo-concepts {
  color: white;
}
#TabHome:hover #logo-llc {
  color: white;
}

.logo-img {
  width: 80px;
  pointer-events: none;
  transition: transform 0.1s;
}

.logo-text-container {
  text-align: left;
  display: block;
  pointer-events: none;

}
.logo-title {
  margin: 0;
  line-height: 40px;

}
#logo-amp {
  color: white
}
#logo-concepts {
  color: white
}
#logo-llc {
  color: white
}

.nav-button {
  height: 100%;
  width: 20%;
  color: white;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: font-size 0.1s, background-color 0.1s;
}
.nav-button:hover {
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: x-large;
  background-color: rgba(0, 0, 0, 0.5);
}



.white-backdrop {
  position: absolute;
  z-index: 2;
  height: 100vh;
  width: 90vw;
  background-color: white;
}
.mainContent {
  display: flex;
  align-items: center;
  justify-content: center;
  
}















@media screen and (max-width: 1180px) {
  .App-header {
    position: fixed;
    top: 0;
    flex-wrap:wrap;
    height: 80px;
    justify-content: space-evenly;
    background-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0));
  }
  .mainContent {}
  .nav-button {
    color: white;
    /* width: 25%; */
    height: 80px;
    font-size: 0.7em;
  }
  
  .nav-button:hover {
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 0.9em;
  }
  #TabHome {
    align-items: center;
    justify-content: center;
  }
  #logo-amp {
    color: white;
  }
  #logo-concepts {
    color: white;
  }
  #logo-llc {
    color: white;
  }
}

@media screen and (max-width: 600px) {
  .App-header {
    position: fixed;
    top: 0;
    height: 50px;
  }
  .nav-button {
    background-color: rgba(0,0,0,0.5);
    color: white;
    margin: 0%;
    /* width: 20%; */
    height: 40px;
    border: 1px solid black;
    border-bottom: 2px solid black;
    /* box-shadow: 0px 0px 8px black; */
    font-size: 0.65em;
    box-sizing: border-box;
  }
  .nav-button:hover {
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 0.8em;
  }
  #TabHome:hover .logo-img {
    transform: scale(1.0);
  }
  #TabHome:hover {
    cursor: pointer;
    font-weight: bold;
    font-size: 0.8em;
  }
  .logo-img {
    width: 30%;
  } 
  .logo-text-container {
    display: none;
  }

}