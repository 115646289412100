:root {
  --background-color: #e9e9e9;
  --sidebar-background: rgba(40, 40, 40, 1);;
  --sidebar-button: transparent;
  --text-white: #fff;
  --indicator: rgb(250,223,79);
  --bento-background: #fff;
  font-family:sans-serif
}

/*ANIMATIONS*/
@keyframes fadeInUp {
  from {
    opacity: 0.4;
    /* transform: translateY(1rem);  */
  }
  to {
    opacity: 1;
    /* transform: translateY(0); */
  }
}

.dash-app {
  background-color: var(--background-color);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  width: 100vw;
}
.dash-content {
  background-color: var(--background-color);
  margin-left: 15rem; /* Match the width of the sidebar */
  transition: margin-left .3s, width .3s;
  display:flex;
  align-items:flex-end;

  /* min-height: 100%; */
}

 /* SIDEBAR */

.sidebar {
  user-select: none;
  background-color: var(--sidebar-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 100%;
  position: relative;
  transition: margin-left .3s;
  position: fixed;
  box-shadow: 0 0 0.5rem black;
}
.sidebar-hamburger {
  position: absolute;
  top: 0rem;
  right: -2rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  font-size: 1.5rem;
  background-color: var(--sidebar-background);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0rem 0rem 0.5rem 0rem;
}
.sidebar-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 3rem;
  padding-top: 2rem;
  padding-bottom: 4rem;

}
.sidebar-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 12rem;
  height: 100%;
  margin-left: 2rem;
  padding-right: 3rem;
}
.sidebar-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 12rem;
  height: 3rem;


}
.sidebar-item {
  box-sizing: border-box;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 12rem;
  padding: 1rem;
  text-align: left;
  background-color: var(--sidebar-button);
  cursor: pointer;
  color: var(--text-white);
}
.sidebar-item.bottom {
  width: 100%;
  padding: 1rem;
  text-align: left;
  background-color: var(--sidebar-button);
  cursor: pointer;
  color: var(--text-white);
}
.sidebar-item-text {
  width: 8rem;
}
.sidebar-indicator {
  width: 0.5rem;
  height: 140%;
  margin-left: -2rem;
  border-radius: 0 0.25rem 0.25rem 0;
  transition: background-color 0.3s

}




 /* BENTOS */
 .bento-box {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-template-rows: auto;
  gap: 1rem; /* space between grid items */
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding: 2rem;
  overflow-y: auto;
  /* animation: fadeInUp 0.5s; */
}

.bento-base {
  background-color: var(--bento-background);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* border: 2px solid red; */
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transition: box-shadow 0.2s;
}
.bento-base:hover {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
}
.b-click {
  cursor: pointer;
}
.b-search {
  grid-column: span 2; /* search boxes will span two columns */
  height: 4rem;
}

.b-tall {
  grid-column: span 2;
  grid-row: span 40;
}
.b-wide {
  grid-column: span 4;
  grid-row: span 10;
}
.b-fullbox {
  grid-column: span 4;
  grid-row: span 20;
}
.b-large {
  grid-column: span 2; /* large boxes will span two columns */
  grid-row: span 20; /* and two rows */
}

.b-medium {
  grid-column: span 1; /* medium boxes will span one column */
  grid-row: span 20; /* and two rows */
}

.b-small {
  grid-column: span 1;
  grid-row: span 10; 

}
